import React from 'react'
import vd from './assets/Site.mp4'
import { MdHealthAndSafety, MdOtherHouses, MdInventory2 } from "react-icons/md";
import {useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';


const Vid = () => {

  useEffect(() => {
    AOS.init();
  },[]);

  return (
    <div id='farm' className='bg-[#050505] p-32 w-screen h-screen flex justify-center align-middle items-center flex-col overflow-hidden'>
        <video id='video' className='w-[70%] p-10' loop autoPlay muted src={vd}/>
        <div className='text-white flex '>
          <div data-aos="fade-up" data-aos-duration="1000"  data-aos-delay="600" className='w-[500px] flex justify-center align-middle items-center'>
              <MdHealthAndSafety className='text-[200px] text-[#b8973a]' />
          <div className='w-[400px]'>
              <h1 className='font-extrabold text-[20px] uppercase'>1. Ensuring Hygineic and Safety Standards</h1>
              <p className='text-[14px] text-[#7c7c7c]'>Our goal in ensuring the safety and quality of our farm is to maintain it clean and up to world standards.</p>
          </div>

          </div>
          <div data-aos="fade-up" data-aos-duration="1500"  data-aos-delay="800" className='w-[500px] flex justify-center align-middle items-center'>
            <MdOtherHouses className='text-[200px] text-[#b8973a]' />
          <div className='w-[400px]'>
              <h1 className='font-extrabold text-[20px]  uppercase'>2. Inhouse Feeding System</h1>
              <p className='text-[14px] text-[#aaaaa5]'>after evaluation and test the feeding process and animal appetite , digestibility and quality for production we changed the operation gradually to be totally inhouse. </p>
          </div>

          </div>
          <div data-aos="fade-up" data-aos-duration="2000"  data-aos-delay="1000" className='w-[500px] flex justify-center align-middle items-center'>
            <MdInventory2 className='text-[175px] text-[#b8973a]' />
          <div className='w-[400px]'>
              <h1 className='font-extrabold text-[20px] uppercase'>3. Professional and Smart Monitoring</h1>
              <p className='text-[14px] text-[#aaaaa5]'>We have a smart cattle monitoring system to ensure that our quality is maintained throughout the life cycle and that we can trace every cattle in the house.</p>
          </div>
          </div>
        </div>
    </div>
  )
}

export default Vid