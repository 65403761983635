import React from "react";
import Hero from "./components/Hero";
import Hero2 from "./components/Hero2";
import Navbar from "./components/Navbar";
import Vid from "./components/Vid";
import Hero3 from "./components/Hero3";
import Contact from "./components/Contact";
import useScrollSnap from 'react-use-scroll-snap';
import {useRef} from 'react';



function App() {
  const scrollRef = useRef(null);
  useScrollSnap({ ref: scrollRef, duration: 50, delay: 50});
  return (
    <div id="container" className="bg-hero m-0 bg-no-repeat" ref={scrollRef}>
      <Navbar />
      <Hero />
      <Hero2 />
      <Vid />
      <Hero3 />
      <Contact />
    </div>
    );
  }



export default App
 



