import React from 'react'
import logo  from './assets/nav-logo.png';
import {useEffect} from 'react';
import { useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'
import {IoCloseSharp, IoMenuSharp} from 'react-icons/io5'

const Navbar = () => {
    const [nav, setNav]=useState(false)

    const handleNav = () => {
        setNav(!nav)
    }

    useEffect(() => {
        AOS.init();
      },[]);
  return (
<div id='nav-wrapper' className='flex justify-between items-center h-24 w-screen mx-auto px-10 text-white overflow-x-hidden'>
        
        <div data-aos="slide-right" data-aos-duration="2000" id='logo-left' className='w-[200px]'>
          <a href="#home"><img src={logo} alt="logo"/></a>
        </div>

        <ul className='text-l font-Poppins font-medium text-white hidden md:flex'>
            <li data-aos="slide-left" data-aos-duration="500" className='p-8 hover:underline'><a href="#home">Home</a></li>
            <li data-aos="slide-left" data-aos-duration="1000" className='p-8 hover:underline'><a href="#about">About</a></li>
            <li data-aos="slide-left" data-aos-duration="1500" className='p-8 hover:underline'><a href="#farm">Our Farm</a></li>
            <li data-aos="slide-left" data-aos-duration="2000" className='p-8 pr-0 text-white text-center hover:underline'><a href="#contact">Contact Us</a></li>
        </ul>
        <div onClick={handleNav} className='block md:hidden cursor-pointer'>
            {nav ? <IoCloseSharp size={35}/> : <IoMenuSharp size={35}/> }
            
        </div>

        <div className={nav ? 'fixed left-0 top-0 w-[60%] h-full border-r border-r-[#b8973a] bg-[#000000] ease-in-out duration-500 z-10 ' : 'fixed left-[-100%] ease-out duration-500'}>
        <ul className='pt-24 uppercase p-5'>
            <li className='p-5 border-b border-[#b8973a] text-center'><a href="#home">Home</a></li>
            <li className='p-5 border-b border-[#b8973a] text-center'><a href="#about">About</a></li>
            <li className='p-5 border-b border-[#b8973a] text-center'><a href="#farm">Our Farm</a></li>
            <li className='p-5 m-auto mt-5 bg-[#b8973a]  text-white rounded-full w-[200px] text-center hover:bg-[#151515] transition ease-in-out delay-150 cursor-pointer'><a href="#contact">Contact Us</a></li>
        </ul>
        </div>
        </div>
  )
}

export default Navbar