import React from 'react'
import {useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'
import ita from './assets/1.png'
import itb from './assets/2.png'
import itc from './assets/3.png'



const Hero2 = () => {
    useEffect(() => {
        AOS.init();
      },[]);

  return (
<div id='about' className=' bg-[#eeeeee] h-full w-full overflow-y-hidden'>

    <div id='home' className='flex-col w-screen h-screen my-10 flex items-center justify-evenly'>

        <div className=' flex flex-col justify-center align-middle items-center  text-[#ffffff]'>
        <div data-aos="slide-left"  data-aos-duration="1500" className='text-center font-bold text-7xl mt-[30px] leading-[60px] text-[#b8973a]'>WHO WE ARE?</div>
        <p data-aos="slide-right"  data-aos-duration="1700"  className='px-7 mt-5  h-[200px] text-center text-[18px] text-[#505050] lg: w-[500px]'> <span className='font-extrabold text-[#b8973a]'>IMC</span>  is one of <a href='https://introgroup.org/intro/about.html' className=' underline cursor-pointer'>intro group</a> companies that operates a highly efficient slaughterhouse, a quarry, and a farm located in a unique place at SC Zone with access to Sokhna Port.</p>
        </div>
    <div>
    <div data-aos="slide-left"  data-aos-duration="1700" className=' text-center font-bold text-7xl leading-[60px] text-[#b8973a] '>OUR CLIENTS?</div>
    
        <div className='flex flex-col  justify-center align-middle items-center sm:flex-row'>
        <img data-aos="zoom-in"  data-aos-delay="600"  src={ita} alt="Carrefour" />
        <img  data-aos="zoom-in"  data-aos-delay="700" src={itb} alt="Seoudi" />
        <img  data-aos="zoom-in"  data-aos-delay="800" src={itc} alt="Oscar" />
        </div>

    </div>
    </div>
</div>
  )
}

export default Hero2