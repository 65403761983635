import React from 'react'
import { MdHome, MdCall, MdEmail } from "react-icons/md";



const Contact = () => {
  return (
    <div id='contact' className='w-screen h-[500px] bg-black flex justify-center align-middle items-center' >
      <div className='w-[350px] flex flex-col align-middle justify-center items-center'>
      <MdHome className='text-[100px] text-[#b8973a]'/>
      <h1 className='text-white font-bold text-3xl text-center'>Visit Us</h1>
      <p className='text-[#979797] text-center'>7th Floor, 2 Simon bolivar sq, Garden City, Cairo, Egypt</p>
      </div>
      <div className='w-[350px] flex flex-col align-middle justify-center items-center'>
      <MdCall className='text-[100px] text-[#b8973a]'/>
      <h1 className='text-white font-bold text-3xl text-center'>Call Us</h1>
      <p className='text-[#979797] text-center'>(+02)27 905 127</p>
      </div>
      <div className='w-[350px] flex flex-col align-middle justify-center items-center'>
      <MdEmail className='text-[100px] text-[#b8973a]'/>
      <h1 className='text-white font-bold text-3xl text-center'>Send A Message</h1>
      <p className='text-[#979797] text-center'>info@imcegp.com</p>
      </div>
    </div>
  )
  
}

export default Contact