import React from 'react'
import {useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';


const Hero = () => {

  useEffect(() => {
    AOS.init();
  },[]);

  return (
    <div>
      <div className='h-[90vh] w-screen overflow-scroll overflow-y-hidden'>
      <div id='home' className='flex-col w-screen h-[90vh] flex items-center justify-center'>

  <div className=' flex-col justify-center align-middle items-center  text-[#ffffff]'>
    <div className=' w-[500px] flex flex-col align-middle justify-center  items-center ml-[35px]'>
      <div data-aos="slide-left"  data-aos-duration="1500" className=' text-center font-bold text-[110px] mt-[30px] leading-[100px]'>Upgrade Your Meat Experiance</div>
      <div data-aos="slide-up" className=' text-center font-bold text-8xl text-[#b8973a]'>To The Next Level</div>
    </div>
      <p className=' mx-7 p-5 h-[200px] text-center text-[16px] text-[#e7e7e7] lg: w-[500px] '>Established in 2021 at Sukhna Automated Slaughterhouse.</p>
  </div>

    <div  className='bg-[#b8973a] p-6 py-8 rounded-[35px] h-[50px] flex justify-center font-semibold text-xl animate-bounce text-[#eeeeee] hover:bg-[#101010] border-double border-4 border-[#b8973a] '>
      <a href="#video" className='flex gap-2 align-middle justify-center items-center '>Let's Take You On A Tour 
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="White" className="w-8 h-8">
            <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-.53 14.03a.75.75 0 001.06 0l3-3a.75.75 0 10-1.06-1.06l-1.72 1.72V8.25a.75.75 0 00-1.5 0v5.69l-1.72-1.72a.75.75 0 00-1.06 1.06l3 3z" clipRule="evenodd" />
      </svg>
      </a>
    </div>
</div>
</div>
    </div>
  )
}

export default Hero

