import React from 'react'
import {useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'
import img from './assets/half-head.png'


const Hero3 = () => {
    useEffect(() => {
        AOS.init();
      },[]);
  return (
    <div className='w-screen h-screen bg-[#eeeeee] flex' >
        <div className='flex-col w-screen h-screen flex items-center justify-evenly'>
        <h1 data-aos="slide-left"  data-aos-duration="1700" className=' text-center font-bold text-7xl leading-[60px] text-[#b8973a]'>MISSION</h1>
        <p data-aos="slide-right"  data-aos-duration="1700"  className='px-7 h-[200px] mt-[-100px] text-center text-[18px] text-[#505050] lg: w-[500px]'>Due to the Egyptian market's deficiency, our aim is to shift beef supply to premium class and high demand market, while opening a global market for fresh meat exports through marbled special cuts, and carcass.
        </p>
        <h1 data-aos="slide-left"  data-aos-duration="1700" className=' text-center font-bold text-7xl leading-[60px] text-[#b8973a]'>VISION</h1>
        <p data-aos="slide-right"  data-aos-duration="1700"  className='px-7 mt-[-100px] h-[200px] text-center text-[18px] text-[#505050] lg: w-[500px]'>The firm's main goal is to become no. 1 company in Egypt through offering differentiated product for Egypt and regional market delivering fresh beef of the highest quality with competitive price.
        </p>
        </div>
        <img src={img} alt="half-head"  data-aos="fade-left" />
    </div>
  )
}

export default Hero3